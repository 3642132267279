import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import multicall from 'utils/multicall'
import { getStakingChefAddress } from 'utils/addressHelpers'
import masterChefABI from 'config/abi/masterchef.json'
import { farmsConfig } from 'config/constants'
import useRefresh from './useRefresh'
import useWeb3 from './useWeb3'

const useAllEarnings = () => {
  const [balances, setBalance] = useState([])
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()
  const web3 = useWeb3()

  useEffect(() => {
    const fetchAllBalances = async () => {
      const liveFarms = farmsConfig.filter(item => item.multiplier !== '0X')
      const calls = liveFarms.map((farm) => ({
        address: farm.masterChefAddress,
        name: 'pendingReward',
        params: [farm.pid, account],
      }))
      // add staking pool
      calls.push({
        address: getStakingChefAddress(),
        name: 'pendingReward',
        params: [0, account],
      })

      const res = await multicall(masterChefABI, calls, web3)

      setBalance(res)
    }

    if (account && (web3.currentProvider as any).isMetaMask) {
      fetchAllBalances()
    }
  }, [account, fastRefresh])

  return balances
}

export default useAllEarnings
