import Web3 from 'web3'
import { HttpProviderOptions } from 'web3-core-helpers'
import getRpcUrl from 'utils/getRpcUrl'

const RPC_URL = getRpcUrl()
const RPC_URL1 = getRpcUrl()

const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 } as HttpProviderOptions)

const httpProvider1 = new Web3.providers.HttpProvider(RPC_URL1, { timeout: 10000 } as HttpProviderOptions)

const web3NoAccount = new Web3(httpProvider)
const web4NoAccount = new Web3(httpProvider1)

const getWeb3NoAccount = () => {
  return web3NoAccount
}

const getWeb4NoAccount = () => {
  return web4NoAccount
}

export { getWeb3NoAccount, getWeb4NoAccount }
export default web3NoAccount
