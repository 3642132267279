import BigNumber from 'bignumber.js'
import Web3 from 'web3'

import { BIG_TEN } from './bigNumber'

/**
 * Take a formatted amount, e.g. 15 BNB and convert it to full decimal value, e.g. 15000000000000000
 */
export const getDecimalAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).times(BIG_TEN.pow(decimals))
}

export const getBalanceAmount = (amount: BigNumber, decimals = 18) => {
  return new BigNumber(amount).dividedBy(BIG_TEN.pow(decimals))
}

/**
 * This function is not really necessary but is used throughout the site.
 */
export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  return getBalanceAmount(balance, decimals).toNumber()
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18, decimalsToAppear?: number) => {
  return getBalanceAmount(balance, decimals).toFixed(decimalsToAppear)
}

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }
  return number.toLocaleString(undefined, options)
}

export function getBalance(balance: string, decimals = 18): number {
  return parseFloat(Web3.utils.fromWei(balance))
}

export function formatNumberAmount(n: number, displayDecimal = 2) {
  if (typeof n === 'string') n = parseFloat(n)

  if (!n) return n
  const order = Math.floor(Math.log10(n) / 3)
  if (order < 0) {
    return n.toFixed(displayDecimal)
  }

  const units = ['', 'K', 'M', 'B', 'T', 'Q']
  const num = n / 1000 ** order
  return units[order] !== undefined ? num.toFixed(displayDecimal) + units[order] : '∞'
}
