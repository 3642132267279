import React, { useEffect, useState } from 'react'
import RefHeader from './components/RefHeader'
import RefProfile from './components/RefProfile'
import RefWithdrawBlock from './components/RefWithdrawBlock'
import RefTable from './components/RefTable'
import styled from 'styled-components'
import rewardRef from './hooks/rewardRef'
import { usePriceCakeBusd } from 'state/hooks'

const RefContainer = styled.div`
  background: url() ('images/background.svg');
  /* height: 1000px; */
`
const RefContentGroup = styled.div`
  /* padding: 20px 150px; */
  width: 80%;
  max-width: 1200px;
  margin: auto;
  .ref-withdraw-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`
export default React.memo(function Ref() {
  console.log('renderr')
  const rewardRefHook = rewardRef()
  const cakePriceBusd = usePriceCakeBusd()
  console.log(cakePriceBusd, rewardRefHook, 'rewardRefHook')
  return (
    <RefContainer>
      <RefHeader />
      <RefContentGroup>
        <br />
        <br />
        <RefProfile
          totalIncome={rewardRefHook.totalIncome}
          reward={rewardRefHook.reward}
          numRefActive={rewardRefHook.numRefActive}
          claimReward={rewardRefHook.claimReward}
          cakePriceBusd={cakePriceBusd}
          account={rewardRefHook.account}
        />
        <br />
        <div className="ref-withdraw-group">
          <RefWithdrawBlock
            bgIcon="images/ref/swap-icon.svg"
            title="SWAP referral"
            subtitle="| 5% share from swap fee"
          />
          <RefWithdrawBlock
            bgIcon="images/ref/farms-icon.svg"
            title="CeloFARM referral"
            subtitle="| 2% share from profits"
          />
          <RefWithdrawBlock
            bgIcon="images/ref/polyhero-icon.svg"
            title="CeloHERO referral"
            subtitle="| 2% share from profits"
          />
          <RefWithdrawBlock
            bgIcon="images/ref/polylaunch-icon.svg"
            title="CeloLAUNCH referral"
            subtitle="| 2% share from profits"
          />
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <RefTable />
      </RefContentGroup>
    </RefContainer>
  )
})
