const tokens = {
  matic: {
    symbol: 'MATIC',
    address: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      80001: '',
    },
  },
  clx: {
    symbol: 'CLX',
    address: {
      42220: '0xC7144Fa865c9f3a6836167A51531A2CC8b0ab5fD',
    },
    decimals: 18,
    projectLink: 'https://polydex.fi/',
  },
  celo: {
    symbol: 'CELO',
    address: {
      42220: '0x471EcE3750Da237f93B8E339c536989b8978a438'
    },
    decimals: 18,
  },

  cusd: {
    symbol: 'cUSD',
    address: {
      42220: '0x765DE816845861e75A25fCA122bb6898B8B1282a'
    },
    decimals: 18,
  },
  ceur: {
    symbol: 'cEUR',
    address: {
      42220: '0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73'
    },
    decimals: 18,
  },
  busd: {
    symbol: 'BUSD',
    address: {
      42220: '0x65eb36F7D78EE2D435689287FA962151ba5Cd123',
    },
    decimals: 18,
    projectLink: 'https://interdefi.org',
  },

  fplx: {
    symbol: 'fCLX',
    address: {
      137: '0x28151Ba48963019ddB509FbB6D5761F3494b3828',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://polydex.fi/',
  },
  wmatic: {
    symbol: 'WMATIC',
    address: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  weth: {
    symbol: 'WETH',
    address: {
      4220: '0xE919F65739c26a42616b7b8eedC6b5524d1e3aC4',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  wbtc: {
    symbol: 'WBTC',
    address: {
      42220: '0xBe50a3013A1c94768A1ABb78c3cB79AB28fc1aCE',
      80001: '',
    },
    decimals: 8,
    projectLink: '',
  },
  ube: {
    symbol: 'UBE',
    address: {
      42220: '0x00Be915B9dCf56a3CBE739D9B9c202ca692409EC',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  sushi: {
    symbol: 'SUSHI',
    address: {
      42220: '0xD15EC721C2A896512Ad29C671997DD68f9593226',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  mobi: {
    symbol: 'MOBI',
    address: {
      42220: '0x73a210637f6F6B7005512677Ba6B3C96bb4AA44B',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      80001: '',
    },
    decimals: 6,
    projectLink: '',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
      80001: '',
    },
    decimals: 6,
    projectLink: '',
  },
  testusdc: {
    symbol: 'Test USDC',
    address: {
      137: '0x50b6f3360d85ad186594654b2a76d2718bad10b5',
      80001: '',
    },
    decimals: 6,
    projectLink: '',
  },
  dai: {
    symbol: 'DAI',
    address: {
      137: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  sds: {
    symbol: 'SDS',
    address: {
      137: '0x352db329B707773DD3174859F1047Fb4Fd2030BC',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://safedollar.fi/',
  },

  meeb: {
    symbol: 'MEEB',
    address: {
      137: '0x64aFDF9e28946419E325d801Fb3053d8B8FFdC23',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://meebmaster.com',
  },
  top: {
    symbol: 'TOP',
    address: {
      137: '0x4438da4ef8e791a9964d27742676e6a70beb2514',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://tokenplay.app/',
  },
  tiny: {
    symbol: 'TINY',
    address: {
      137: '0xB5B9601b62988C8348B10D81e02FA8400Ed55983',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://tinyhero.io/',
  },
  owl: {
    symbol: 'OWL',
    address: {
      137: '0x9085B4d52c3e0B8B6F9AF6213E85A433c7D76f19',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://owl.games/',
  },
  duel: {
    symbol: 'DUEL',
    address: {
      137: '0x0000000000000000000000000000000000000000',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://duelverse.io/',
  },
  proton: {
    symbol: 'PROTON',
    address: {
      137: '0x673e064eCb5889Ef163ec3C3eA693b8f74BBC95D',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://owl.games/',
  },
  owltest: {
    symbol: 'OWL',
    address: {
      137: '0x49958E40928F0738e447FAd87B93E1F4Fb445DA7',
      80001: '',
    },
    decimals: 18,
    projectLink: 'https://owl.games/',
  },
  xdot: {
    symbol: 'XDOT',
    address: {
      137: '0x49958E40928F0738e447FAd87B93E1F4Fb445DA7',
      80001: '',
    },
    decimals: 18,
    projectLink: '',
  },
  mtoken: {
    symbol: 'MToken',
    address: {
      42220: '0xd699B0571110cdfa61febFB5f65f3c26e1cfB938',
    },
    decimals: 18,
    projectLink: '',
  },
}

export default tokens
