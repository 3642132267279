import { useMemo } from 'react'
import useWeb3 from 'hooks/useWeb3'
import {
  getBep20Contract,
  getCakeContract,
  getBunnyFactoryContract,
  getBunnySpecialContract,
  getPancakeRabbitContract,
  getProfileContract,
  getIfoV1Contract,
  getIfoV2Contract,
  getLotteryContract,
  getLotteryTicketContract,
  getMasterchefContract,
  getPointCenterIfoContract,
  getSouschefContract,
  getClaimRefundContract,
  getTradingCompetitionContract,
  getEasterNftContract,
  getErc721Contract,
  getPredictionsContract,
  getChainlinkOracleContract,
  getSouschefV2Contract,
  getFPlxContract,
  getCLXContract,
  getStakingChefContract,
  getTokenSaleByUSDCContract,
  getTokenLockerContract,
  getLPCompensateContract,
  getFutureContract,
  getBuyNFTContract,
  getIAOByUSDCContract, getILOContract,
  getExternalILOContract,
  airDropContract
} from 'utils/contractHelpers'
import { usePoolFromId } from 'state/hooks'
import {getAddress, getStakingChefAddress} from 'utils/addressHelpers'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useIfoV1Contract = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getIfoV1Contract(address, web3), [address, web3])
}

export const useIfoV2Contract = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getIfoV2Contract(address, web3), [address, web3])
}

export const useERC20 = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getBep20Contract(address, web3), [address, web3])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getErc721Contract(address, web3), [address, web3])
}

export const useCake = () => {
  const web3 = useWeb3()
  return useMemo(() => getCakeContract(web3), [web3])
}

export const useBunnyFactory = () => {
  const web3 = useWeb3()
  return useMemo(() => getBunnyFactoryContract(web3), [web3])
}

export const usePancakeRabbits = () => {
  const web3 = useWeb3()
  return useMemo(() => getPancakeRabbitContract(web3), [web3])
}

export const useProfile = () => {
  const web3 = useWeb3()
  return useMemo(() => getProfileContract(web3), [web3])
}

export const useLottery = () => {
  const web3 = useWeb3()
  return useMemo(() => getLotteryContract(web3), [web3])
}

export const useCLX = () => {
  const web3 = useWeb3()
  return useMemo(() => getCLXContract(web3), [web3])
}

export const useLotteryTicket = () => {
  const web3 = useWeb3()
  return useMemo(() => getLotteryTicketContract(web3), [web3])
}

export const useMasterchef = (chefAddress?: string) => {
  chefAddress = chefAddress || getStakingChefAddress()
  const web3 = useWeb3()
  return useMemo(() => getMasterchefContract(chefAddress, web3), [web3, chefAddress])
}

export const useSousChef = (id) => {
  const web3 = useWeb3()
  const pool = usePoolFromId(id)
  return useMemo(() => {
    if(pool.sousId === 0)
      return getStakingChefContract(getAddress(pool.contractAddress), web3)
    return getSouschefContract(id, web3)
  }, [id, web3, pool?.sousId, pool?.contractAddress])
}

export const useSousChefV2 = (id) => {
  const web3 = useWeb3()
  return useMemo(() => getSouschefV2Contract(id, web3), [id, web3])
}

export const usePointCenterIfoContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getPointCenterIfoContract(web3), [web3])
}

export const useBunnySpecialContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getBunnySpecialContract(web3), [web3])
}

export const useClaimRefundContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getClaimRefundContract(web3), [web3])
}

export const useTradingCompetitionContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getTradingCompetitionContract(web3), [web3])
}

export const useEasterNftContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getEasterNftContract(web3), [web3])
}

export const usePredictionsContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getPredictionsContract(web3), [web3])
}

export const useChainlinkOracleContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getChainlinkOracleContract(web3), [web3])
}

export const useFPlxContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getFPlxContract(web3), [web3])
}

export const useFutureContract = (contractAddress: string) => {
  const web3 = useWeb3()
  return useMemo(() => getFutureContract(contractAddress, web3), [web3])
}

export const useTokenSaleByUSDCContract = (contractAddress: string) => {
  const web3 = useWeb3()
  return useMemo(() => getTokenSaleByUSDCContract(contractAddress, web3), [web3])
}

export const useIAOByUSDCContract = (contractAddress: string) => {
  const web3 = useWeb3()
  return useMemo(() => getIAOByUSDCContract(contractAddress, web3), [web3])
}

export const useILOContract = (contractAddress: string) => {
  const web3 = useWeb3()
  return useMemo(() => getILOContract(contractAddress, web3), [web3])
}

export const useExternalILOContract = (contractAddress: string) => {
  const web3 = useWeb3()
  return useMemo(() => getExternalILOContract(contractAddress, web3), [web3])
}


export const useTokenLockerContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getTokenLockerContract(web3), [web3])
}

export const useLPCompensationContract = () => {
  const web3 = useWeb3()
  return useMemo(() => getLPCompensateContract(web3), [web3])
}

export const useBuyNFT = () => {
  const web3 = useWeb3()
  return useMemo(() => getBuyNFTContract(web3), [web3])
}

export const useAirDrop = () => {
  const web3 = useWeb3()
  return useMemo(() => airDropContract(web3), [web3])
}
