import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    id: 0,
    pid: 0,
    lpSymbol: 'CLX',
    lpAddresses: {
      42220: '0xC7144Fa865c9f3a6836167A51531A2CC8b0ab5fD',
    },
    token: tokens.clx,
    quoteToken: tokens.clx,
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    addLiquidityUrl:
      'https://exchange.celodex.org/swap?inputCurrency=0x765DE816845861e75A25fCA122bb6898B8B1282a&outputCurrency=0xC7144Fa865c9f3a6836167A51531A2CC8b0ab5fD',
    startReward: 1636124400,
  },
  {
    id: 1,
    pid: 1,
    lpSymbol: 'CLX',
    lpAddresses: {
      42220: '0xC7144Fa865c9f3a6836167A51531A2CC8b0ab5fD',
    },
    token: tokens.clx,
    quoteToken: tokens.clx,
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 7d lockup',
    addLiquidityUrl:
      'https://exchange.celodex.org/swap?inputCurrency=0x765DE816845861e75A25fCA122bb6898B8B1282a&outputCurrency=0xC7144Fa865c9f3a6836167A51531A2CC8b0ab5fD',
    startReward: 1636124400,
  },
  {
    id: 2,
    pid: 2,
    lpSymbol: 'CLX',
    lpAddresses: {
      42220: '0xC7144Fa865c9f3a6836167A51531A2CC8b0ab5fD',
    },
    token: tokens.clx,
    quoteToken: tokens.clx,
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 30d lockup',
    addLiquidityUrl:
      'https://exchange.celodex.org/swap?inputCurrency=0x765DE816845861e75A25fCA122bb6898B8B1282a&outputCurrency=0xC7144Fa865c9f3a6836167A51531A2CC8b0ab5fD',
    startReward: 1636124400,
  },
  {
    id: 3,
    pid: 3,
    lpSymbol: 'CLX',
    lpAddresses: {
      42220: '0xC7144Fa865c9f3a6836167A51531A2CC8b0ab5fD',
    },
    token: tokens.clx,
    quoteToken: tokens.clx,
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 180d lockup',
    addLiquidityUrl:
      'https://exchange.celodex.org/swap?inputCurrency=0x765DE816845861e75A25fCA122bb6898B8B1282a&outputCurrency=0xC7144Fa865c9f3a6836167A51531A2CC8b0ab5fD',
    startReward: 1636124400,
  },

  {
    id: 4,
    pid: 4,
    lpSymbol: 'CLX-CELO',
    lpAddresses: {
      42220: '0xBf939E1C4A5DeFf958dB7B724dBF623e3110d65B',
    },
    token: tokens.clx,
    quoteToken: tokens.celo,
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    tag: '🔒 30m lockup',
    warning: '⚠ 70% rewards locked',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0xBf939E1C4A5DeFf958dB7B724dBF623e3110d65B',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0xBf939E1C4A5DeFf958dB7B724dBF623e3110d65B',
    startReward: 1636124400,
  },

  {
    id: 5,
    pid: 5,
    lpSymbol: 'CLX-cUSD',
    lpAddresses: {
      42220: '0x9E51605EBd0682EF7ee16042a8037cc27d1B5828',
    },
    token: tokens.clx,
    quoteToken: tokens.cusd,
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    tag: '🔒 30m lockup',
    warning: '⚠ 70% rewards locked',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0x9E51605EBd0682EF7ee16042a8037cc27d1B5828',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0x9E51605EBd0682EF7ee16042a8037cc27d1B5828',
    startReward: 1636124400,
  },
  {
    id: 6,
    pid: 6,
    lpSymbol: 'CLX-cEUR',
    lpAddresses: {
      42220: '0x7857f3Df397D8Ba43E8B2161C4A84CEfd72AECec',
    },
    token: tokens.clx,
    quoteToken: tokens.ceur,
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    tag: '🔒 30m lockup',
    warning: '⚠ 70% rewards locked',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0x7857f3Df397D8Ba43E8B2161C4A84CEfd72AECec',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0x7857f3Df397D8Ba43E8B2161C4A84CEfd72AECec',
    startReward: 1636124400,
  },
  {
    id: 16,
    pid: 16,
    lpSymbol: 'CELO-BUSD',
    lpAddresses: {
      42220: '0xE3991B3D065A4e77d442279b5aE698E597A09619',
    },
    token: tokens.celo,
    quoteToken: tokens.busd,
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0xE3991B3D065A4e77d442279b5aE698E597A09619',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0xE3991B3D065A4e77d442279b5aE698E597A09619',
    startReward: 1637852400,
  },
  {
    id: 7,
    pid: 7,
    lpSymbol: 'cUSD-cEUR',
    lpAddresses: {
      42220: '0x76bde4463501a135e29deD69437a42bdA3990D31',
    },
    token: tokens.cusd,
    quoteToken: tokens.ceur,
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    tag: '🔒 1h lockup',
    warning: '⚠ 70% rewards locked',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0x76bde4463501a135e29deD69437a42bdA3990D31',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0x76bde4463501a135e29deD69437a42bdA3990D31',
    startReward: 1636117200,
  },
  {
    id: 8,
    pid: 8,
    lpSymbol: 'cUSD-CELO',
    lpAddresses: {
      42220: '0x6A1f92769a94cF893ee5A580A3A66e9FBA1E025F',
    },
    token: tokens.cusd,
    quoteToken: tokens.ceur,
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0x6a1f92769a94cf893ee5a580a3a66e9fba1e025f',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0x6a1f92769a94cf893ee5a580a3a66e9fba1e025f',
    startReward: 1636117200,
  },
  {
    id: 15,
    pid: 15,
    lpSymbol: 'cEUR-CELO',
    lpAddresses: {
      42220: '0x07b254399cb12d9789ae52ad37511dd775e074de',
    },
    token: tokens.cusd,
    quoteToken: tokens.ceur,
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0x07b254399cb12d9789ae52ad37511dd775e074de',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0x07b254399cb12d9789ae52ad37511dd775e074de',
    startReward: 1636560000,
  },
  {
    id: 9,
    pid: 9,
    lpSymbol: 'wBTC-wETH',
    lpAddresses: {
      42220: '0x9e2f8A88B7Ff2B6daFD84fab459648350529E72b',
    },
    token: tokens.wbtc,
    quoteToken: tokens.weth,
    multiplier: '0X',
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0x9e2f8A88B7Ff2B6daFD84fab459648350529E72b',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0x9e2f8A88B7Ff2B6daFD84fab459648350529E72b',
    startReward: 1636117200,
  },
  {
    id: 10,
    pid: 10,
    lpSymbol: 'wETH-cUSD',
    lpAddresses: {
      42220: '0xb2438aAa5F23c58b45B5f5EB8AD840a2e45a1187',
    },
    token: tokens.weth,
    quoteToken: tokens.cusd,
    multiplier: '0X',
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0xb2438aAa5F23c58b45B5f5EB8AD840a2e45a1187',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0xb2438aAa5F23c58b45B5f5EB8AD840a2e45a1187',
    startReward: 1636117200,
  },

  {
    id: 11,
    pid: 11,
    lpSymbol: 'wBTC-cUSD',
    lpAddresses: {
      42220: '0x1Fdd00c9994Ff21C881938F60828b54564A26e35',
    },
    token: tokens.wbtc,
    quoteToken: tokens.cusd,
    multiplier: '0X',
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0x1Fdd00c9994Ff21C881938F60828b54564A26e35',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0x1Fdd00c9994Ff21C881938F60828b54564A26e35',
    startReward: 1636117200,
  },

  {
    id: 12,
    pid: 12,
    lpSymbol: 'UBE-CELO',
    lpAddresses: {
      42220: '0xEcbEfc5F77a8009037Aac4D620f8179Bb74Ff25b',
    },
    token: tokens.ube,
    quoteToken: tokens.celo,
    multiplier: '0X',
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0xEcbEfc5F77a8009037Aac4D620f8179Bb74Ff25b',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0xEcbEfc5F77a8009037Aac4D620f8179Bb74Ff25b',
    startReward: 1636117200,
  },
  {
    id: 13,
    pid: 13,
    lpSymbol: 'SUSHI-CELO',
    lpAddresses: {
      42220: '0x9a1dF1A1Da698F39089447A133A674085997c967',
    },
    token: tokens.sushi,
    quoteToken: tokens.celo,
    multiplier: '0X',
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0x9a1dF1A1Da698F39089447A133A674085997c967',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0x9a1dF1A1Da698F39089447A133A674085997c967',
    startReward: 1636117200,
  },
  {
    id: 14,
    pid: 14,
    lpSymbol: 'MOBI-CELO',
    lpAddresses: {
      42220: '0x92d5F9ba21feC94bACeD51baa248DB6e27199D59',
    },
    token: tokens.mobi,
    quoteToken: tokens.celo,
    multiplier: '0X',
    masterChefAddress: '0x20186B0D87bb187e1a11366288607128C4C92761',
    platform: 'PolyDEX',
    warning: '⚠ 70% rewards locked',
    tag: '🔒 1h lockup',
    addLiquidityUrl: 'https://exchange.celodex.org/add/0x92d5F9ba21feC94bACeD51baa248DB6e27199D59',
    removeLiquidityUrl: 'https://exchange.celodex.org/remove/0x92d5F9ba21feC94bACeD51baa248DB6e27199D59',
    startReward: 1636117200,
  },
]

export default farms
