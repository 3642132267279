import tokens from './tokens'

export const NftConfig = {
  0: {
    platformId: 0,
    platform: 'Meeb Master',
    name: 'Meeb',
    symbol: 'MATIC',
    addressNft: '0xdb79D2f90Cce00BAe3092E83FfFc1FC8C53A36A5',
    tokens: [tokens.matic],
    baseUri: 'https://nftapi.meebmaster.com/static/nft/POLYGON/MEEBMASTER/',
    detailUrl: 'https://app.meebmaster.com/nfts/',
    router: 'MEEB',
  },
  1: {
    platformId: 1,
    platform: 'Tiny Hero',
    name: 'TinyHero',
    symbol: 'MATIC',
    addressNft: '0x5837962CEC0b8A619406C3B9F7D6154406bD802e',
    tokens: [tokens.matic],
    baseUri: 'https://api.tinyhero.io/static/nft/TinyHero/',
    detailUrl: 'https://www.tinyhero.io/markets/hero/',
    router: 'TINY',
  },
  2: {
    platformId: 2,
    platform: 'Tiny Super Hero',
    name: 'SuperHero',
    symbol: 'MATIC',
    addressNft: '0xfa5a4697b6834b77dbe8471e8f14874281bbfeaa',
    tokens: [tokens.matic],
    baseUri: 'https://api.tinyhero.io/static/nft/SuperTinyHero/',
    detailUrl: '#',
    router: 'TINY_SUPER',
  },
}
