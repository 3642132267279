import React from 'react'
import { StyledButton } from '../styled'
import styled from 'styled-components'

const RefHeaderContainerFluid = styled.div`
  width: 100%;
  /* max-width: 1200px; */
  height: auto;
  background: rgb(226, 255, 236);
  /* padding: 20px 150px; */
  padding-top: 20px;
  padding-bottom: -10px;
  @media (max-width: 1100px) {
    padding-bottom: 10px;
  }
`
const RefHeaderContainer = styled.div`
  width: 100%;
  width: 80%;
  max-width: 1200px;
  margin: auto;
  .ref-header-holder {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    max-width: 900px;
    /* margin: auto; */
    @media (max-width: 1358px) {
      width: 850px;
    }
    @media (max-width: 1100px) {
      width: 100%;
    }
  }
`
const RefHeaderTextGroup = styled.div`
  width: 72%;
  max-width: 622px;
  /* background: yellow; */
  @media (max-width: 700px) {
    width: 100%;
  }
`
const BrandingText = styled.span`
  font-size: 30px;
  font-weight: bolder;
  color: rgb(0, 210, 120);
`
const SubBrandingText = styled.div`
  font-size: 19px;
  font-weight: bold;
  color: rgb(10, 39, 66);
  padding-top: 5px;
`
const HeaderDescriptionGroup = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  padding-top: 20px;
  div {
    line-height: 18px;
  }
`
const HeroLogo = styled.div`
  width: 166px;
  height: 130px;
  background: url('/images/ref/hero-header-icon.svg');
  background-size: cover;
  @media (max-width: 750px) {
    width: 0;
    height: 0;
  }
  /* margin-right: 300px; */
`
export default function RefHeader() {
  return (
    <RefHeaderContainerFluid>
      <RefHeaderContainer>
        <div className="ref-header-holder">
          <RefHeaderTextGroup>
            <div>
              <BrandingText>Celo</BrandingText>
              <BrandingText style={{ color: 'rgb(0,210,120)' }}>REF</BrandingText>
              <SubBrandingText>
                Join CeloREF and enjoy the most advanced
                <span style={{ color: 'rgb(10, 39, 66)' }}> referral programme</span> in DeFi
              </SubBrandingText>
            </div>
            <HeaderDescriptionGroup>
              <div>
                Earn $CLX commission reward for your referrals swaps and CeloLAUNCH purchases and up to 5% of their
                profit from CeloFARM and CeloHERO
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end', paddingLeft: 10 }}>
                <StyledButton width={120}>Learn More</StyledButton>
              </div>
            </HeaderDescriptionGroup>
          </RefHeaderTextGroup>
          <HeroLogo />
        </div>
      </RefHeaderContainer>
    </RefHeaderContainerFluid>
  )
}
