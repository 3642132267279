import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchefv2.json'
import multicall from 'utils/multicall'
import { BIG_TEN } from 'utils/bigNumber'
import { getAddress, getStakingChefAddress } from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'
import axios from 'axios'

const fetchFarms = async (farmsToFetch: FarmConfig[]) => {
  const [rewardPerSecond] = await multicall(masterchefABI, [
    {
      address: getStakingChefAddress(),
      name: 'rewardPerSecond',
    }
  ])

  const data = await Promise.all(
    farmsToFetch.map(async (farmConfig) => {
      const lpAddress = getAddress(farmConfig.lpAddresses)
      const calls = [
        // Balance of token in the LP contract
        {
          address: getAddress(farmConfig.token.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of quote token on LP contract
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'balanceOf',
          params: [lpAddress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: lpAddress,
          name: 'balanceOf',
          params: [farmConfig.masterChefAddress],
        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: getAddress(farmConfig.token.address),
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: getAddress(farmConfig.quoteToken.address),
          name: 'decimals',
        },
      ]

      const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply, tokenDecimals, quoteTokenDecimals] =
        await multicall(erc20, calls)

      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

      // Total value in staking in quote token value
      const lpTotalInQuoteToken = new BigNumber(quoteTokenBalanceLP)
        .div(BIG_TEN.pow(quoteTokenDecimals))
        .times(new BigNumber(2))
        .times(lpTokenRatio)

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      const tokenAmount = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals)).times(lpTokenRatio)
      const quoteTokenAmount = new BigNumber(quoteTokenBalanceLP)
        .div(BIG_TEN.pow(quoteTokenDecimals))
        .times(lpTokenRatio)

      const [info, totalAllocPoint] = await multicall(masterchefABI, [
        {
          address: farmConfig.masterChefAddress,
          name: 'poolInfo',
          params: [farmConfig.pid],
        },
        {
          address: farmConfig.masterChefAddress,
          name: 'totalAllocPoint',
        },
      ])

      const allocPoint = new BigNumber(info.allocPoint._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
        poolWeight: poolWeight.toJSON(),
        multiplier: farmConfig.multiplier ? farmConfig.multiplier : `${allocPoint.div(1000).toString()}X`,
        tokenBalance: new BigNumber(tokenBalanceLP).toJSON(),
        quoteTokenBalanceLP: new BigNumber(quoteTokenBalanceLP).toJSON(),
        rewardPerSecond: new BigNumber(rewardPerSecond).times(poolWeight).toJSON()
      }
    }),
  )
  return data
}

const fetchFarmsInfo = async (farmsToFetch: FarmConfig[]) => {
  const apiData = await fetchFarmsFromApi();
  let mpNameAndFarm = {}
  apiData.forEach(e => {
    mpNameAndFarm[e.pid] = e;
  });

  return farmsToFetch.map((farmConfig) => {
    const currentPool = mpNameAndFarm[farmConfig.id] || {}
    return {
      ...farmConfig,
      apiData: {
        multiplier: `${(currentPool.allocPoint || 0)/1000}X`,
        tvl: currentPool.tvl || 0,
        apr: currentPool.apr || 0
      }
    }
  })
}

export const fetchFarmsFromApi = async () => {
  const apiData = await axios.get('https://celo-api.polydex.fi/v2/farm')
  return apiData.data?.data

}

export default fetchFarmsInfo
