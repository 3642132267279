import styled from 'styled-components'

export const StyledButton = styled.button<{ width?: number }>`
  padding: 10px 20px;
  background: #01d374;
  color: #fff;
  border-radius: 15px;
  width: ${(props) => props.width || 120}px;
  border: none;
  cursor: pointer;
`
