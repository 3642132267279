import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { useFarmFromId, usePoolFromId } from 'state/hooks'
import useWeb3 from 'hooks/useWeb3'
import { fetchFarmUserDataAsync, updateUserBalance, updateUserPendingReward } from 'state/actions'
import { soushHarvest, soushHarvestBnb, harvest, harvestAll } from 'utils/callHelpers'
import {getAddress} from 'utils/addressHelpers'
import { useMasterchef, useSousChef } from './useContract'

export const useHarvest = (farmId: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const farm = useFarmFromId(farmId)
  const masterChefContract = useMasterchef(farm.masterChefAddress)
  const web3 = useWeb3();
  const handleHarvest = useCallback(async () => {
    const txHash = await harvest(masterChefContract, farm.pid, account)
    dispatch(fetchFarmUserDataAsync(account, web3))
    return txHash
  }, [account, dispatch, farm?.pid, masterChefContract])

  return { onReward: handleHarvest }
}

export const useHarvestEachPlatform = (masterChefAddress: string) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const masterChefContract = useMasterchef(masterChefAddress)
  const web3 = useWeb3();
  const handleHarvest = useCallback(async () => {
    const txHash = await harvestAll(masterChefContract, account)
    dispatch(fetchFarmUserDataAsync(account, web3))
    return txHash
  }, [account, dispatch, masterChefContract])

  return { onReward: handleHarvest }
}

export const useHarvestAll = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const masterChefContract = useMasterchef()
  const web3 = useWeb3();

  const handleHarvest = useCallback(async () => {
    const txHash = await harvestAll(masterChefContract, account)
    dispatch(fetchFarmUserDataAsync(account, web3))
    return txHash
  }, [account, dispatch, masterChefContract])

  return { onReward: handleHarvest }
}

export const useSousHarvest = (id, isUsingBnb = false) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  const pool = usePoolFromId(id)
  const sousId = pool.sousId;

  const sousChefContract = useSousChef(id)
  const masterChefContract = useMasterchef(getAddress(pool.contractAddress))

  const handleHarvest = useCallback(async () => {
    if (sousId === 0) {
      await harvest(masterChefContract, 0, account)
    } else if (isUsingBnb) {
      await soushHarvestBnb(sousChefContract, account)
    } else {
      await soushHarvest(sousChefContract, account)
    }
    dispatch(updateUserPendingReward(id, account))
    dispatch(updateUserBalance(id, account))
  }, [account, dispatch, isUsingBnb, masterChefContract, sousChefContract,sousId, id])

  return { onReward: handleHarvest }
}
