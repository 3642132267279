import { useCallback, useContext } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from 'web3-eth-contract'
import { ethers } from 'ethers'
import { useAppDispatch } from 'state'
import { fetchFarmUserDataAsync, updateUserAllowance } from 'state/actions'
import { useFarmFromId } from 'state/hooks'
import { approve } from 'utils/callHelpers'
import useWeb3 from 'hooks/useWeb3'
import { getBep20Contract, getErc721Contract } from 'utils/contractHelpers'
import tokens from 'config/constants/tokens'
import { useCake, useLottery, useMasterchef, useCLX, useSousChef } from './useContract'
import { Token } from 'config/constants/types'
import NFTContext from '../contexts/NFTContext'

// Approve a Farm
export const useApproveFarm = (lpContract: Contract, farmId: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const farm = useFarmFromId(farmId)
  const masterChefContract = useMasterchef(farm.masterChefAddress)
  const web3 = useWeb3()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, masterChefContract, account)
      dispatch(fetchFarmUserDataAsync(account, web3))
      return tx
    } catch (e: any) {
      return false
    }
  }, [account, dispatch, lpContract, (web3?.currentProvider as any)?.isMetaMask, masterChefContract])

  return { onApprove: handleApprove }
}

// Approve a Pool
export const useSousApprove = (lpContract: Contract, id) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const sousChefContract = useSousChef(id)

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, sousChefContract, account)
      dispatch(updateUserAllowance(id, account))
      return tx
    } catch (e: any) {
      return false
    }
  }, [account, dispatch, lpContract, sousChefContract, id])

  return { onApprove: handleApprove }
}

// Approve the lottery
export const useLotteryApprove = () => {
  const { account } = useWeb3React()
  const cakeContract = useCake()
  const lotteryContract = useLottery()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(cakeContract, lotteryContract, account)
      return tx
    } catch (e: any) {
      return false
    }
  }, [account, cakeContract, lotteryContract])

  return { onApprove: handleApprove }
}

// Approve for migration
export const useMigrationApprove = () => {
  const { account } = useWeb3React()
  const cakeContract = useCake()
  const plxv2Contract = useCLX()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(cakeContract, plxv2Contract, account)
      return tx
    } catch (e: any) {
      return false
    }
  }, [account, cakeContract, plxv2Contract])

  return { onApprove: handleApprove }
}

export const useApprove = (spender: string, quoteToken: Token) => {
  const { account, chainId } = useWeb3React()
  const web3 = useWeb3()
  const contract = getBep20Contract(quoteToken.address[chainId], web3)

  const handleApprove = useCallback(async () => {
    try {
      const tx = await contract.methods.approve(spender, ethers.constants.MaxUint256).send({ from: account })
      return tx
    } catch (e: any) {
      return false
    }
  }, [account, contract])

  return { onApprove: handleApprove }
}

// Approve an IFO
export const useIfoApprove = (tokenContract: Contract, spenderAddress: string) => {
  const { account } = useWeb3React()
  const onApprove = useCallback(async () => {
    const tx = await tokenContract.methods.approve(spenderAddress, ethers.constants.MaxUint256).send({ from: account })
    return tx
  }, [account, spenderAddress, tokenContract])

  return onApprove
}

// Approve the NFT
export const useNFTApprove = (spender: string, address: string) => {
  const { account } = useWeb3React()
  const web3 = useWeb3()
  const contract = getErc721Contract(address, web3)

  const handleApprove = useCallback(
    async (tokenId: any) => {
      if (!account) return

      try {
        const tx = await contract.methods.approve(spender, tokenId).send({ from: account })
        return tx
      } catch (e: any) {
        return false
      }
    },
    [account],
  )

  return { onApprove: handleApprove }
}
