import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import {useFarmFromId, usePoolFromId} from 'state/hooks'
import useWeb3 from 'hooks/useWeb3'
import { getAddress } from 'utils/addressHelpers'
import { fetchFarmUserDataAsync, updateUserStakedBalance, updateUserBalance } from 'state/actions'
import { stake, harvestAndRestake, sousStake, sousStakeBnb } from 'utils/callHelpers'
import { useMasterchef, useSousChef } from './useContract'

const useStake = (id: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const farm = useFarmFromId(id)
  const masterChefContract = useMasterchef(farm.masterChefAddress);
  const web3 = useWeb3();

  const handleStake = useCallback(
    async (amount: string) => {
      const txHash = await stake(masterChefContract, farm.pid, amount, account)
      dispatch(fetchFarmUserDataAsync(account, web3))
      console.info(txHash)
    },
    [account, dispatch, masterChefContract, farm.pid],
  )

  return { onStake: handleStake }
}

export const useSousStake = (id: number, isUsingBnb = false) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  const pool = usePoolFromId(id);
  const sousId = pool.sousId;

  const masterChefContract = useMasterchef(getAddress(pool.contractAddress))
  const sousChefContract = useSousChef(id)

  const handleStake = useCallback(
    async (amount: string, decimals: number) => {
      if (sousId === 0) {
        await stake(masterChefContract, 0, amount, account)
      } else if (isUsingBnb) {
        await sousStakeBnb(sousChefContract, amount, account)
      } else {
        await sousStake(sousChefContract, amount, decimals, account)
      }
      dispatch(updateUserStakedBalance(id, account))
      dispatch(updateUserBalance(id, account))
    },
    [account, dispatch, isUsingBnb, masterChefContract, sousChefContract, id, sousId],
  )

  const handleHarvestAndStake = useCallback(
    async () => {
      await harvestAndRestake(masterChefContract, account)
      dispatch(updateUserStakedBalance(id, account))
      dispatch(updateUserBalance(id, account))
    },
    [account, dispatch, id, masterChefContract],
  )

  return { onStake: handleStake, onHarvestAndRestake: handleHarvestAndStake }
}

export default useStake
