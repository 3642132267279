export default {
  masterChef: {
    80001: '',
    137: '0xAF019F09b887E611Cc7C7263503027787AA46BA6',
  },
  stakingChefAddress: {
    80001: '',
    137: '0xf61fdc0F479305a0E7566bBeAB46196bc0aFd997',
    42220: '0x806c374aC7a2A67e2B2EA31AF369b60a6A650fC2'
  },
  tokenSalePolygon: {
    80001: '',
    137: '0x1d20aCCe1968a1142Fd89e34782aE7a1606F8640',
  },
  safeShareTokenSalePolygon: {
    80001: '',
    137: '0x7b517A3677BcedEFaCEF701EbB4A77D2281e79C4',
  },
  safeShareTokenSaleBSC: '0x70568C2BfcF9287b399F4322349aac53480f08E0',
  meebTokenSalePolygon: {
    80001: '',
    137: '0xf1d9A6AC8460184723B53578cc8f1A23C122960A',
  },
  meebTokenSaleBSC: '0x64aFDF9e28946419E325d801Fb3053d8B8FFdC23',

  tokenSaleBSC: '0x33c04bD4Ae93336BbD1024D709f4A313cC858EBe',
  tokenLocker: {
    80001: '',
    137: '0xb92e099Be693679d14aEA143c62548Bb9e74A03f',
    42220: '0xD4A7d5ca5232976Ebf9C28b7231bFDBF4c4eEe95'
  },
  LPCompensation: {
    80001: '',
    137: '0xE90bc288E0C3F42d630bc4Abc05A224cDF9cEcf8',
  },
  sousChef: {
    80001: '',
    137: '',
  },
  lottery: {
    80001: '',
    137: '',
  },
  lotteryNFT: {
    80001: '',
    137: '',
  },
  multiCall: {
    137: '0x114C4042B11a2b16F58Fe1BFe847589a122F678a',
    80001: '0x935Bfe9AfaA2Be26049ea4EDE40A3A2243361F87',
    42220: '0xb1AB897A81F1E0b55c45ffF602A7E679b79b5c07'
  },
  pancakeProfile: {
    137: '',
    80001: '',
  },
  pancakeRabbits: {
    137: '',
    80001: '',
  },
  bunnyFactory: {
    137: '',
    80001: '',
  },
  claimRefund: {
    137: '',
    80001: '',
  },
  pointCenterIfo: {
    137: '',
    80001: '',
  },
  bunnySpecial: {
    137: '',
    80001: '',
  },
  tradingCompetition: {
    137: '=',
    80001: '',
  },
  easterNft: {
    137: '',
    80001: '',
  },
  cakeVault: {
    137: '0xf61fdc0F479305a0E7566bBeAB46196bc0aFd997', //masterChef
    80001: '',
  },
  predictions: {
    137: '',
    80001: '',
  },
  chainlinkOracle: {
    137: '',
    80001: '',
  },
  buyNFT: {
    137: '0xB8A12FF2622980835999Ad48bEE1B4aa817BBDd1',
    80001: '',
  },
  airDrop: {
    137: '',
    42220: '0x8F551B86fF9101D06cE079844e9E899C8E1A650D'
  },
}
