import React from 'react'
import { Flex, TooltipText, IconButton, useModal, CalculateIcon, Skeleton, useTooltip } from '@polydex/uikit'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber } from 'utils/formatBalance'
import { getPoolApr } from 'utils/apr'
import { tokenEarnedPerThousandDollarsCompounding, getRoi } from 'utils/compoundApyHelpers'
import { useBusdPriceFromToken, useGetApiPrice } from 'state/hooks'
import Balance from 'components/Balance'
import ApyCalculatorModal from 'components/ApyCalculatorModal'
import { Pool } from 'state/types'
import { BASE_EXCHANGE_URL } from 'config'

interface TotalStakeRowProps {
  pool: Pool
  isAutoVault?: boolean
  compoundFrequency?: number
  performanceFee?: number
}

const TotalStakeRow: React.FC<TotalStakeRowProps> = ({ pool, isAutoVault = false, compoundFrequency = 1, performanceFee = 0 }) => {
  const { t } = useTranslation()
  const totalStaked = pool.totalStaked ? getBalanceNumber(pool.totalStaked, pool.stakingToken.decimals) : 0;

  const stakingTokenPriceAsNumber = useGetApiPrice(pool.stakingToken.address[137])

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <TooltipText>Total Staked</TooltipText>
        <Flex alignItems="center">
          <Balance
            fontSize="16px"
            value={totalStaked}
            decimals={0}
            unit={` ${  pool.stakingToken.symbol}`}
            bold
          />
        </Flex>
    </Flex>
  )
}

export default TotalStakeRow
