import BigNumber from 'bignumber.js'
import React from 'react'
import { Box, CardBody, CardRibbon, Flex, Text } from '@polydex/uikit'
import styled from 'styled-components'
import { commify } from 'ethers/lib/utils'
import UnlockButton from 'components/UnlockButton'
import { useTranslation } from 'contexts/Localization'
import { BIG_ZERO } from 'utils/bigNumber'
import { Pool } from 'state/types'
import AprRow from './AprRow'
import { StyledCard, StyledCardInner } from './StyledCard'
import CardFooter from './CardFooter'
import StyledCardHeader from './StyledCardHeader'
import CardActions from './CardActions'
import UnstakingFeeCountdownRow from '../CakeVaultCard/UnstakingFeeCountdownRow'
import { getBalanceNumber } from '../../../../utils/formatBalance'
import TotalStakedRow from './TotalStakeRow'

const InlineText = styled(Text)`
  display: inline;
`

const PoolCard: React.FC<{ pool: Pool; account: string }> = ({ pool, account }) => {
  const { sousId, stakingToken, earningToken, isFinished, userData } = pool
  const { t } = useTranslation()
  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const accountHasStakedBalance = stakedBalance.gt(0)
  // console.log('totalStaked:', getBalanceNumber(pool.totalStaked, stakingToken.decimals).toFixed(0))
  const totalStaked = pool.totalStaked ? getBalanceNumber(pool.totalStaked, stakingToken.decimals).toFixed(0) : 0

  return (
    <StyledCard
      isFinished={isFinished}
      ribbon={isFinished && <CardRibbon variantColor="textDisabled" text={t('Finished')} />}
    >
      <StyledCardInner>
        <StyledCardHeader
          isStaking={accountHasStakedBalance}
          earningTokenSymbol={earningToken.symbol}
          stakingTokenSymbol={stakingToken.symbol}
          isFinished={isFinished}
        />
        <CardBody>
          <AprRow pool={pool} />
          <TotalStakedRow pool={pool} />
          <Box mt="12px">
            <Text mb="10px" fontSize="14px" color="textSubtle" bold>
              {pool.isLocked ? t('70% Locked reward') : 'No Locked Reward'}
            </Text>
          </Box>
          <Box mt="8px">
            {pool.isTaxed ? (
              <UnstakingFeeCountdownRow />
            ) : (
              <>
                <Text mb="4px">No unstaking fee</Text>
              </>
            )}
          </Box>
          <div className="btn-pool">
            <Flex mt="24px" flexDirection="column">
              {account ? (
                <CardActions pool={pool} stakedBalance={stakedBalance} />
              ) : (
                <>
                  <Text mb="10px" textTransform="uppercase" fontSize="12px" color="textSubtle" bold>
                    {t('Start earning')}
                  </Text>
                  <UnlockButton />
                </>
              )}
            </Flex>
          </div>
        </CardBody>
        <CardFooter pool={pool} account={account} />
      </StyledCardInner>
    </StyledCard>
  )
}

export default PoolCard
