import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import {
  fetchFarmUserDataAsync,
  updateUserStakedBalance,
  updateUserBalance,
  updateUserPendingReward,
} from 'state/actions'
import { useFarmFromId, usePoolFromId } from 'state/hooks'
import useWeb3 from 'hooks/useWeb3'
import { getAddress } from 'utils/addressHelpers'
import { unstake, sousUnstake, sousEmergencyUnstake, emergencyWithdraw } from 'utils/callHelpers'
import { useMasterchef, useSousChef } from './useContract'

const useUnstake = (id: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const farm = useFarmFromId(id)
  const masterChefContract = useMasterchef(farm?.masterChefAddress)
  const web3 = useWeb3();

  const handleUnstake = useCallback(
    async (amount: string) => {
      const txHash = await unstake(masterChefContract, farm?.pid, amount, account)
      dispatch(fetchFarmUserDataAsync(account, web3))
      console.info(txHash)
    },
    [account, dispatch, masterChefContract, id, farm?.pid],
  )

  return { onUnstake: handleUnstake }
}

export const useEmergencyWithdraw = (id: number) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const farm = useFarmFromId(id)
  const masterChefContract = useMasterchef(farm?.masterChefAddress)
  const web3 = useWeb3();

  const handleUnstake = useCallback(
    async (amount: string) => {
      const txHash = await emergencyWithdraw(masterChefContract, farm?.pid, amount, account)
      dispatch(fetchFarmUserDataAsync(account, web3))
      console.info(txHash)
    },
    [account, dispatch, masterChefContract, farm?.pid],
  )

  return { onEmergencyWithdraw: handleUnstake }
}

export const useSousUnstake = (id, enableEmergencyWithdraw = false) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const pool = usePoolFromId(id)
  const sousId = pool.sousId;

  const masterChefContract = useMasterchef(getAddress(pool.contractAddress))
  const sousChefContract = useSousChef(id)

  const handleUnstake = useCallback(
    async (amount: string, decimals: number) => {
      if (sousId === 0) {
        if(id === 2){
          await emergencyWithdraw(masterChefContract, 0, amount, account)
        }else{
          await unstake(masterChefContract, 0, amount, account)
        }

      } else if (enableEmergencyWithdraw) {
        const txHash = await sousEmergencyUnstake(sousChefContract, account)
        console.info(txHash)
      } else {
        const txHash = await sousUnstake(sousChefContract, amount, decimals, account)
        console.info(txHash)
      }
      dispatch(updateUserStakedBalance(id, account))
      dispatch(updateUserBalance(id, account))
      dispatch(updateUserPendingReward(id, account))
    },
    [account, dispatch, enableEmergencyWithdraw, masterChefContract, sousChefContract, sousId, id],
  )

  return { onUnstake: handleUnstake }
}

export default useUnstake
