import React from 'react'
import styled from 'styled-components'
import { useRouteMatch, Link } from 'react-router-dom'
import {
  ButtonMenu,
  ButtonMenuItem,
  Button,
  HelpIcon,
  Toggle,
  Text,
  Flex,
  NotificationDot,
  Link as UiKitLink,
} from '@polydex/uikit'
import { useTranslation } from 'contexts/Localization'

const StyledWrapper = styled.div<{ isExact: boolean }>`
  a {
    background-color: ${({ isExact }) => {
      return isExact ? '#01d374' : 'transparent'
    }};

    color: ${({ isExact }) => {
      return isExact ? '#fff' : 'inherit'
    }};

    padding-left: 12px;
    padding-right: 12px;
  }

  span {
    a {
      background-color: ${({ isExact }) => {
        return !isExact ? '#01d374' : 'transparent'
      }};

      color: ${({ isExact }) => {
        return !isExact ? '#fff' : 'inherit'
      }};
    }
  }
`

const PoolTabButtons = ({ stakedOnly, setStakedOnly, hasStakeInFinishedPools }) => {
  const { url, isExact } = useRouteMatch()
  const { t } = useTranslation()

  return (
    <StyledWrapper isExact={isExact}>
      <Flex alignItems="center" justifyContent="center" mb="32px">
        <Flex alignItems="center" flexDirection={['column', null, 'row', null]}>
          <ButtonMenu activeIndex={isExact ? 0 : 1} scale="sm" variant="subtle">
            <ButtonMenuItem as={Link} to={`${url}`}>
              {t('Live')}
            </ButtonMenuItem>
            <NotificationDot show={hasStakeInFinishedPools}>
              <ButtonMenuItem as={Link} to={`${url}/history`}>
                {t('Finished')}
              </ButtonMenuItem>
            </NotificationDot>
          </ButtonMenu>
          <Flex mt={['4px', null, 0, null]} ml={[0, null, '24px', null]} justifyContent="center" alignItems="center">
            <Toggle scale="sm" checked={stakedOnly} onChange={() => setStakedOnly((prev) => !prev)} />
            <Text ml="8px">{t('Staked only')}</Text>
          </Flex>
        </Flex>
      </Flex>
    </StyledWrapper>
  )
}

export default PoolTabButtons
