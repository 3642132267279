import React, { useCallback, useState } from 'react'
import axios from 'axios'
import { Menu as UikitMenu, Text, Box, LinkExternal } from '@polydex/uikit'
import { useWeb3React } from '@web3-react/core'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import useToast from 'hooks/useToast'
import useTokenBalance from 'hooks/useTokenBalance'
import { usePriceCakeBusd } from 'state/hooks'
import config from './config'
import { useHarvestAll } from '../../hooks/useHarvest'
import { getCLXAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL } from '../../config'
import useAllEarnings from '../../hooks/useAllEarnings'

const Menu = (props) => {
  const [faucetLoading, setFaucetLoading] = useState(false)
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const cakePriceUsd = usePriceCakeBusd()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { toastError, toastSuccess } = useToast()

  const onFaucet = useCallback(async () => {
    // if(maticBalance.isLessThanOrEqualTo(0)){
    setFaucetLoading(true)
    const result = await axios.get(`https://celo-api.polydex.fi/v1/faucet?address=${account}`).then((res) => {
      return res.data
    })
    setFaucetLoading(false)
    if (result.status === 200) {
      toastSuccess(
        t('CeloFaucet sent!'),
        <Box>
          <Text as="p" mb="8px">
            {t('Some $CELO have been sent to your wallet')}
          </Text>
          {result.tx && (
            <LinkExternal href={`https://explorer.celo.org/tx/${result.tx}`}>{t('View on Celo Explorer')}</LinkExternal>
          )}
        </Box>,
      )
    } else {
      toastError(result.message || 'You are greylisted')
    }
    // }else{
    //   toastError('You have owned some $MATIC already')
    // }
  }, [account, t, toastSuccess, toastError])
  const cakePriceBusd = usePriceCakeBusd()

  // plx in wallet
  const cakeBalance = useTokenBalance(getCLXAddress())
  const cakeBalancePrice = new BigNumber(cakeBalance).multipliedBy(cakePriceBusd).toNumber()

  //plx harvest
  const allEarnings = useAllEarnings()
  const earningsSum = allEarnings.reduce((accum, earning) => {
    const earningNumber = new BigNumber(earning)
    if (earningNumber.eq(0)) {
      return accum
    }
    return accum + earningNumber.div(DEFAULT_TOKEN_DECIMAL).toNumber()
  }, 0)
  const earningsBusd = new BigNumber(earningsSum).multipliedBy(cakePriceBusd).toNumber()

  // harvest all plx
  const { onReward } = useHarvestAll()
  const harvestAllFarms = useCallback(async () => {
    try {
      await onReward()
    } catch (error: any) {
      // TODO: find a way to handle when the user rejects transaction or it fails
    } finally {
    }
  }, [onReward])

  return (
    <UikitMenu
      account={account}
      login={login}
      logout={logout}
      isDark={false}
      // toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config(t)}
      faucet={onFaucet}
      faucetLoading={faucetLoading}
      plxInWallet={cakeBalance.toNumber()}
      pricePlxInWallet={cakeBalancePrice}
      plxHarvest={earningsSum}
      pricePlxHarvest={earningsBusd}
      harvestAll={harvestAllFarms}
      // profile={{
      //   username: profile?.username,
      //   image: profile?.nft ? `/images/nfts/${profile.nft?.images.sm}` : undefined,
      //   profileLink: '/profile',
      //   noProfileLink: '/profile',
      //   showPip: !profile?.username,
      // }}
      {...props}
    />
  )
}

export default Menu
