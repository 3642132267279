import React from 'react'
import styled from 'styled-components'
import { Box } from '@polydex/uikit'
import Container from '../layout/Container'
import farmer_mascot from 'assets/img/farmer_mascot.svg'
import polyhero_mascot from 'assets/img/polyhero_mascot.svg'

const Outer = styled(Box)<{ background?: string }>`
  background: #e2ffec;

  h1 {
    color: #00d278;
  }
`

const Inner = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;
`

const Meme = styled.div<{ icon: string }>`
  display: none;
  background-image: url('${({ icon }) => icon}');
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 400px;
  height: 200px;
  top: 27px;
  right: 20%;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
    top: 87px;
    height: 100px;
    right: 5px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    display: block;
    width: 400px;
    height: 170px;
    top: 33px;
    right: 10%;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    display: block;
    width: 400px;
    height: 200px;
    top: 27px;
    right: 20%;
  }
`

const PageHeader: React.FC<{ background?: string; icon?: string }> = ({ background, icon, children, ...props }) => (
  <Outer background={background} {...props}>
    <Inner>
      {children}
      {icon && <Meme icon={icon === "polyhero_mascot" ? polyhero_mascot : farmer_mascot} />}
    </Inner>
  </Outer>
)

export default PageHeader
