import { BigNumber } from '@ethersproject/bignumber'
import React from 'react'
import styled from 'styled-components'
import { StyledButton } from '../styled'
import bs58 from 'getbs58'
import { message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const RefProfileContainer = styled.div`
  width: 100%;
  height: 160px;
  border-radius: 16px;
  background: #05d373;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1025px) {
    height: auto;
    background: none;
  }
`
const ProfileBlock = styled.div`
  height: 100%;
  width: 53%;
  padding: 20px 17px;

  @media (max-width: 1025px) {
    width: 100%;
    border-radius: 16px;
    background: #35cce1;
    margin-bottom: 20px;
  }
`
const BalanceBlock = styled.div`
  border-radius: 16px;
  width: 47%;
  .top-part {
    background: #40484f;
    border-bottom: solid 1px #fff;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .bottom-part {
    background: #2a3137;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .balance-partial-block {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .balance-info-group {
      display: flex;
      color: #fff;
      padding-left: 10px;
      align-items: center;
      .btn-withdraw {
        @media (max-width: 1310px) {
          width: 120px;
        }
        @media (max-width: 450px) {
          width: 180px;
        }
      }
    }
  }
  @media (max-width: 1025px) {
    width: 100%;
    height: 200px;
  }
  @media (max-width: 480px) {
    height: 300px;
    .balance-partial-block {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
`
const ProfileTitleLine = styled.div`
  color: #fff;
  display: flex;
  justify-content: space-between;
  img {
    width: 13px;
    height: 13px;
    /* margin-top: 5px; */
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`
const ProfileInfoLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  .profile-chain-logo {
    background: url('/images/ref/chain-icon-polyref-logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    width: 25%;
    height: 60px;
  }
  .profile-link-group {
    color: #0e2e6b;
    width: 70%;
    padding-right: 10px;
    .profile-link-title {
      color: #2a3136;

      padding-left: 15px;
      font-size: 14px;
    }
    .profile-link {
      margin-top: 2px;
      width: 100%;
      height: 42px;
      line-height: 42px;
      padding: 0 15px;
      border-radius: 8px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .link {
        color: rgb(42, 49, 54);

        width: 88%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .copy-btn {
        width: 23px;
        height: 30px;
        background: url('images/ref/copy_icon.svg');
        cursor: pointer;
      }
    }
  }
`
const IconBackground = styled.div<{ background: string }>`
  display: inline-block;
  width: 45px;
  height: 40px;
  background: url(${(props) => props.background});
  background-size: cover;
  background-repeat: no-repeat;
`

interface Props {
  totalIncome: number
  reward: number
  numRefActive: number
  cakePriceBusd: any
  claimReward: any
  account: any
}
export default function RefProfile({ totalIncome, reward, numRefActive, cakePriceBusd, claimReward, account }: Props) {
  const onClaimReward = () => {
    // if(reward == 0) {
    //     return
    // }
    claimReward()
  }
  const refLink = `${window.location.origin}/?referral=${account ? `${bs58.encode(account ?? '')}` : ''}`
  return (
    <RefProfileContainer>
      <ProfileBlock>
        <ProfileTitleLine>
          <div>Your CeloREF Profile</div>
          <div style={{ display: 'flex', alignItems: 'center', color: '#2a3136', paddingRight: '10px' }}>
            <img alt="" src="/images/ref/history-icon.svg" />
            &nbsp;Profile history
          </div>
        </ProfileTitleLine>
        <ProfileInfoLine>
          <div className="profile-chain-logo"></div>
          <div className="profile-link-group">
            <div className="profile-link-title">Your Referral link</div>
            <div className="profile-link">
              <div className="link">{refLink}</div>
              <CopyToClipboard text={refLink} onCopy={() => message.info('Copied to clipboard')}>
                <div className="copy-btn" />
              </CopyToClipboard>
            </div>
          </div>
        </ProfileInfoLine>
      </ProfileBlock>
      <BalanceBlock>
        <div className="top-part balance-partial-block">
          <div className="balance-info-group" style={{ color: '#FFF', fontSize: 13 }}>
            <IconBackground background="/images/ref/active-referrals-icon.svg" />
            <div style={{ fontSize: 30, fontWeight: 'bold', paddingLeft: '3px', lineHeight: '40px' }}>
              {numRefActive}
            </div>
            <div style={{ padding: '8px 0 0 3px', height: '40px', fontSize: 12 }}>
              <div>Active</div>
              <div>Referrals</div>
            </div>
          </div>
          <div className="balance-info-group">
            <IconBackground background="/images/ref/total-income-icon.svg" />
            <div style={{ paddingLeft: 5, lineHeight: '20px' }}>
              <div style={{ fontSize: 19, fontWeight: 'bold' }}>{totalIncome.toFixed(2)} CLX</div>
              <div style={{ fontSize: 12 }}>Your Total Referral Income</div>
            </div>
          </div>
        </div>
        <div className="bottom-part balance-partial-block">
          <div className="balance-info-group">
            <IconBackground background="/images/ref/claimable-rewards-icon.svg" />
            <div style={{ color: '#36e4cd', height: 60, lineHeight: '20px', paddingLeft: 5 }}>
              <div style={{ fontSize: 12 }}>Claimable Rewards</div>
              <div style={{ fontSize: 20, fontWeight: 'bold' }}>{reward.toFixed(2)} CLX</div>
              <div style={{ color: '#FFF', fontSize: 12 }}>~${reward * parseFloat(cakePriceBusd.toString())}</div>
            </div>
          </div>
          <div className="balance-info-group">
            <StyledButton
              className="btn-withdraw"
              width={160}
              style={{ fontSize: 14, fontWeight: 'bold', background: '#05d373', color: '#101010' }}
              onClick={() => onClaimReward()}
            >
              WITHDRAW ALL
            </StyledButton>
          </div>
        </div>
      </BalanceBlock>
    </RefProfileContainer>
  )
}
