import { MenuEntry } from '@polydex/uikit'
import { ContextData } from '../../contexts/Localization/types'

const config: (t: (key: string, data?: ContextData) => string) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Exchange'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Swap'),
        href: 'https://exchange.celodex.org/swap',
      },
      {
        label: t('Liquidity'),
        href: 'https://exchange.celodex.org/liquidity',
      },
      {
        label: t('Charts'),
        href: 'https://info.celodex.org/',
      },
    ],
  },
  {
    label: t('CeloFARM'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('CeloVAULT'),
    icon: 'VaultIcon',
    href: '/#',
    status: {
      text: 'Soon',
      color: 'warning',
    },
  },
  {
    label: t('CeloHERO'),
    icon: 'PoolIcon',
    href: '/#',
    status: {
      text: 'Soon',
      color: 'warning',
    },
  },
  {
    label: 'CeloLAUNCH',
    icon: 'IfoIcon',
    href: '/#',
    status: {
      text: 'Soon',
      color: 'warning',
    },
  },
  // {
  //   label: t('CeloSTORE'),
  //   icon: 'NftIcon',
  //   href: '/polystore',
  //   status: {
  //     text: 'New',
  //     color: 'warning',
  //   },
  //   items: [
  //     {
  //       label: t('NFT Store'),
  //       href: '/polystore',
  //     },
  //     {
  //       label: t('My Wallet'),
  //       href: '/polystore/my-nft',
  //     },
  //     // {
  //     //   label: t('CREATE NFT'),
  //     //   href: '/polystore/create-nft',
  //     // },
  //   ],
  // },
  {
    label: t('CeloREF'),
    icon: 'ReferralIcon',
    href: '/referral',
  },
  // {
  //   label: t('CeloVESTING'),
  //   icon: 'FutureIcon',
  //   href: '/poly-vesting',
  // },
  {
    label: t('InterGames'),
    icon: 'TicketIcon',
    href: 'https://games.interdefi.org',
  },
  {
    label: t('Audit by TechRate'),
    icon: 'TeamBattleIcon',
    href: 'https://github.com/polydex-fi/polydex-swap-core/blob/5fdd6b85550235800cd980ccb8fab9bd5247a7af/PolydexSwap.pdf',
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/polydex-fi',
      },
      {
        label: 'Docs',
        href: 'https://docs.celodex.org/',
      },
      {
        label: 'Discord',
        href: 'https://discord.gg/WtksUPQSRm',
      },
      {
        label: 'Blog Medium',
        href: 'https://polydex.medium.com',
      },
    ],
  },
]

export default config
