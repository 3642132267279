import React from 'react'
import styled from 'styled-components'
import { StyledButton } from '../styled'

import CLX_Token from 'assets/img/CLX_Token.svg'

const RefBlockContainer = styled.div<{ bgIcon: string }>`
  width: 49%;
  margin-top: 20px;
  height: auto;
  border-radius: 16px;
  box-shadow: rgb(195 236 201) 1px 1px 20px 1px;
  padding: 25px;
  overflow: hidden;
  max-height: 170px;
  background: #fff;
  @media (max-width: 867px) {
    width: 100%;
  }
  .ref-head-line {
    display: flex;
    .ref-title {
      color: #2b3033;
      font-size: 16px;
      font-weight: bold;
    }
    .ref-subtitle {
      color: #05d372;
      font-weight: bold;
    }
  }
  .amount-line {
    padding-top: 15px;
    display: flex;
    .amount-icon {
      width: 40px;
      height: 40px;
      background: url('/images/ref/fplx.svg');
      background-size: cover;
      background-repeat: no-repeat;
    }
    .amount-number {
      font-size: 30px;
      font-weight: bold;
      line-height: 40px;
      color: #2b3033;
      padding-left: 15px;
    }
  }
  .action-line {
    display: flex;
    padding-top: 15px;
    max-width: 270px;
    justify-content: space-between;
    .action-icon {
      width: 38px;
      height: 35px;
      background: url('/images/ref/active-referrals-icon.svg');
      background-size: cover;
      background-repeat: no-repeat;
    }
    .action-text {
      line-height: 33px;
    }
  }
  .block-background-icon {
    position: relative;
    width: 210px;
    height: 160px;
    background: url(${(props) => props.bgIcon});
    opacity: 0.15;
    top: -120px;
    left: 70%;
  }
`
const text = {
  title: 'SWAP referral',
  subtitle: '| 5% share from swap fee',
}
interface Props {
  title: string
  subtitle: string
  bgIcon: string
  amount?: number
  active?: number
}
export default function RefWithdrawBlock({ title, subtitle, bgIcon, amount = 0, active = 0 }) {
  return (
    <RefBlockContainer bgIcon={bgIcon}>
      <div className="ref-head-line">
        <div className="ref-title">{title}</div>
        <div className="ref-subtitle">&nbsp;{subtitle}</div>
      </div>
      <div className="amount-line">
        <div style={{ backgroundImage: `url(${CLX_Token})` }} className="amount-icon"></div>
        <div className="amount-number">{amount} CLX</div>
      </div>
      <div className="action-line">
        <StyledButton width={150}>WITHDRAW</StyledButton>
        <div className="action-icon"></div>
        <div className="action-text">{active} active</div>
      </div>
      <div className="block-background-icon"></div>
    </RefBlockContainer>
  )
}
