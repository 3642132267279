import React, {useState, useEffect, useCallback} from 'react'
import useRefContract from './contract'
import { useWeb3React } from '@web3-react/core'
import Web3 from 'web3'

export default function rewardRef() {
    const refContract = useRefContract().rewardRefContract
    const rewardRefContract = useRefContract().swapRefContract

    const { account } = useWeb3React()

    const [totalIncome, setTotalIncome] = useState(0)
    const [reward, setReward] = useState(0)
    const [numRefActive, setNumRefActive] = useState(0)

    useEffect(() => {
        getTotalEarned()
        getClaimableReward()
        getActiveRef()
    }, [account, refContract])
    const getTotalEarned = useCallback(async () => {
        if(!account){
            setTotalIncome(0)
            return
        }
        let data = await refContract.methods.totalEarned(account).call()
        setTotalIncome(parseFloat(Web3.utils.fromWei(data)))

    }, [account, refContract])

    const getClaimableReward = useCallback(async () => {
        if(!account){
            setReward(0)
            return
        }
        let data = await rewardRefContract.methods.rewardBalance(account).call()
        setReward(parseFloat(Web3.utils.fromWei(data)))

    }, [account, refContract])

    const getActiveRef = useCallback(async () => {
        if(!account){
            setNumRefActive(0)
            return
        }
        let data = await refContract.methods.numberReferralOf(account).call()
        console.log('active ref: ',data)
        setNumRefActive(data)
    }, [account, refContract])

    const claimReward = useCallback(async () => {
        if(!account)
            return
        const estimateLeave = await rewardRefContract.methods
            .withdraw()
            .estimateGas({
                from: account
            })
        return rewardRefContract.methods
            .withdraw()
            .send({ from: account, estimateGas: estimateLeave })
            .on('transactionHash', (tx) => {
                return tx.transactionHash
            })

    }, [rewardRefContract, account])

    const getRefOf = async () => {

    }
    return {totalIncome, numRefActive, reward, claimReward, account, getRefOf}
}

