import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import { ethers } from 'ethers'
import { BIG_TEN, BIG_ZERO } from './bigNumber'

export const approve = async (lpContract, masterChefContract, account) => {
  return lpContract.methods
    .approve(masterChefContract.options.address, ethers.constants.MaxUint256)
    .send({ from: account })
}

export const stake = async (masterChefContract, pid, amount, account) => {
  const referree: string | null = localStorage.getItem('refAddressPolyDex') || ''

  // if (pid === 0) {
  //   if (referree) {
  //     const estimateGasStakingRef = await masterChefContract.methods
  //       .enterStakingWithRef(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString(), referree)
  //       .estimateGas({ from: account })

  //     return masterChefContract.methods
  //       .enterStakingWithRef(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString(), referree)
  //       .send({ from: account, estimateGas: estimateGasStakingRef })
  //       .on('transactionHash', (tx) => {
  //         return tx.transactionHash
  //       })
  //   }

  //   const estimateGasStaking = await masterChefContract.methods
  //     .enterStaking(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
  //     .estimateGas({ from: account })

  //   return masterChefContract.methods
  //     .enterStaking(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
  //     .send({ from: account, estimateGas: estimateGasStaking })
  //     .on('transactionHash', (tx) => {
  //       return tx.transactionHash
  //     })
  // }

  if (referree) {
    const estimateGasRef = await masterChefContract.methods
      .depositWithRef(pid, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString(), referree)
      .estimateGas({ from: account })
    return masterChefContract.methods
      .depositWithRef(pid, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString(), referree)
      .send({ from: account, estimateGasRef })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  }

  const estimateGas = await masterChefContract.methods
    .deposit(pid, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
    .estimateGas({ from: account })

  return masterChefContract.methods
    .deposit(pid, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
    .send({ from: account, estimateGas })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const harvestAndRestake = async (masterChefContract, account) => {
  const estimateGas = await masterChefContract.methods.harvestAndRestake().estimateGas({ from: account })

  return masterChefContract.methods
    .harvestAndRestake()
    .send({ from: account, estimateGas })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const sousStake = async (sousChefContract, amount, decimals = 18, account) => {
  return sousChefContract.methods
    .deposit(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString())
    .send({ from: account, gas: 200000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const sousStakeBnb = async (sousChefContract, amount, account) => {
  return sousChefContract.methods
    .deposit()
    .send({ from: account, gas: 200000, value: new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString() })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const emergencyWithdraw = async (masterChefContract, pid, amount, account) => {
  if (pid === 0) {
    const estimateLeave = await masterChefContract.methods.emergencyWithdraw(pid).estimateGas({ from: account })

    return masterChefContract.methods
      .emergencyWithdraw(pid)
      .send({ from: account, estimateGas: estimateLeave })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  }

  const estimateGas = await masterChefContract.methods.emergencyWithdraw(pid).estimateGas({ from: account })

  return masterChefContract.methods
    .emergencyWithdraw(pid)
    .send({ from: account, estimateGas })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const unstake = async (masterChefContract, pid, amount, account) => {
  // if (pid === 0) {
  //   const estimateLeave = await masterChefContract.methods
  //     .leaveStaking(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
  //     .estimateGas({ from: account })

  //   return masterChefContract.methods
  //     .leaveStaking(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
  //     .send({ from: account, estimateGas: estimateLeave })
  //     .on('transactionHash', (tx) => {
  //       return tx.transactionHash
  //     })
  // }

  const estimateGas = await masterChefContract.methods
    .withdraw(pid, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
    .estimateGas({ from: account })

  return masterChefContract.methods
    .withdraw(pid, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
    .send({ from: account, estimateGas })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const sousUnstake = async (sousChefContract, amount, decimals, account) => {
  const estimateGas = await sousChefContract.methods
    .withdraw(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString())
    .estimateGas({ from: account })

  return sousChefContract.methods
    .withdraw(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString())
    .send({ from: account, estimateGas })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const sousEmergencyUnstake = async (sousChefContract, account) => {
  return sousChefContract.methods
    .emergencyWithdraw()
    .send({ from: account })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const harvest = async (masterChefContract, pid, account) => {
  // if (pid === 0) {
  //   const estimateLeave = await masterChefContract.methods
  //   .leaveStaking('0')
  //   .estimateGas({
  //     from: account
  //   })

  //   return masterChefContract.methods
  //     .leaveStaking('0')
  //     .send({ from: account, estimateGas: estimateLeave })
  //     .on('transactionHash', (tx) => {
  //       return tx.transactionHash
  //     })
  // }

  const estimateGas = await masterChefContract.methods.deposit(pid, '0').estimateGas({
    from: account,
  })
  return masterChefContract.methods
    .deposit(pid, '0')
    .send({ from: account, estimateGas })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const harvestAll = async (masterChefContract, account) => {
  const estimateGas = await masterChefContract.methods.harvestAllRewards().estimateGas({
    from: account,
  })

  return masterChefContract.methods
    .harvestAllRewards()
    .send({ from: account, estimateGas })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const unlock = async (vestingContract, amount, account) => {
  try {
    const estimateLeave = await vestingContract.methods
      .unlock(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
      .estimateGas({
        from: account,
      })

    return vestingContract.methods
      .unlock(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
      .send({ from: account, gas: estimateLeave })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  } catch (e: any) {
    console.log(e.message)
    return e.message
  }
}

export const claimUnlocked = async (vestingContract, account) => {
  try {
    const estimate = await vestingContract.methods.claimUnlocked().estimateGas({
      from: account,
    })

    console.log('account', account)
    console.log('estimate', estimate)

    return vestingContract.methods
      .claimUnlocked()
      .send({ from: account, gas: estimate })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  } catch (e: any) {
    console.log('claimUnlocked exception', e)
  }
}

export const buyTokensSelf = async (tokenSaleContract, amount, account) => {
  const referree: string | null = localStorage.getItem('refAddressPolyDex') || ''

  try {
    if (referree) {
      const estimate = await tokenSaleContract.methods.buyTokensSelfWithRef(amount, referree).estimateGas({
        from: account,
      })
      return tokenSaleContract.methods
        .buyTokensSelfWithRef(amount, referree)
        .send({ from: account, gas: estimate })
        .on('transactionHash', (tx) => {
          return tx.transactionHash
        })
    }

    const estimateLeave = await tokenSaleContract.methods.buyTokensSelf(amount).estimateGas({
      from: account,
    })

    return tokenSaleContract.methods
      .buyTokensSelf(amount)
      .send({ from: account, estimateGas: estimateLeave, gas: estimateLeave })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  } catch (e: any) {
    console.log(e.message)
    return e.message
  }
}

export const lockOnIAO = async (iaoContract, amount, account) => {
  const referree: string | null = localStorage.getItem('refAddressPolyDex') || ''

  try {
    if (referree) {
      const estimate = await iaoContract.methods.lockWithRef(amount, referree).estimateGas({
        from: account,
      })
      return iaoContract.methods
        .lockWithRef(amount, referree)
        .send({ from: account, gas: estimate })
        .on('transactionHash', (tx) => {
          return tx.transactionHash
        })
    }

    const estimateGas = await iaoContract.methods.lock(amount).estimateGas({
      from: account,
    })

    return iaoContract.methods
      .lock(amount)
      .send({ from: account, estimateGas, gas: estimateGas })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  } catch (e: any) {
    console.log(e.message)
    return e.message
  }
}

export const buyILO = async (iloContract, amount, account) => {
  const referree: string | null = localStorage.getItem('refAddressPolyDex') || ''

  try {
    if (referree) {
      const estimate = await iloContract.methods.buyWithRef(amount, referree).estimateGas({
        from: account,
      })
      return iloContract.methods
        .buyWithRef(amount, referree)
        .send({ from: account, gas: estimate })
        .on('transactionHash', (tx) => {
          return tx.transactionHash
        })
    }

    const estimateGas = await iloContract.methods.buy(amount).estimateGas({
      from: account,
    })

    return iloContract.methods
      .buy(amount)
      .send({ from: account, estimateGas, gas: estimateGas })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  } catch (e: any) {
    console.log(e.message)
    return e.message
  }
}

export const claimIAO = async (iaoContract, account) => {
  try {
    const estimateGas = await iaoContract.methods.claim().estimateGas({
      from: account,
    })

    return iaoContract.methods
      .claim()
      .send({ from: account, estimateGas, gas: Math.max(10, estimateGas), gasPrice: '2000000' })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  } catch (e: any) {
    console.log(e.message)
    return e.message
  }
}

export const claimILO = async (iloContract, account) => {
  try {
    const estimateGas = await iloContract.methods.claim().estimateGas({
      from: account,
    })

    return iloContract.methods
      .claim()
      .send({ from: account, estimateGas, gas: Math.max(10, estimateGas), gasPrice: '10000000000' })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  } catch (e: any) {
    console.log(e.message)
    return e.message
  }
}

export const soushHarvest = async (sousChefContract, account) => {
  try {
    const estimateLeave = await sousChefContract.methods.deposit(0).estimateGas({
      from: account,
    })

    return sousChefContract.methods
      .deposit(0)
      .send({ from: account, estimateGas: estimateLeave })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  } catch (e: any) {
    console.log(e.message)
    return e.message
  }
}

export const soushHarvestBnb = async (sousChefContract, account) => {
  return sousChefContract.methods
    .deposit()
    .send({ from: account, gas: 200000, value: BIG_ZERO })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}

export const buyNFT = async (buyNFTContract, account, tokenId) => {
  const estimateGas = await buyNFTContract.methods.buyToken(tokenId).estimateGas({
    from: account,
  })

  return buyNFTContract.methods
    .buyToken()
    .send({ from: account, estimateGas })
    .on('transactionHash', (tx) => {
      return tx.transactionHash
    })
}
