import {getRewardRefContract, getSwapRefContract, getMasterChefRefContract} from '../../../utils/contractHelpers'
import useWeb3 from 'hooks/useWeb3'

export default function useRefContract() {
    const web3 = useWeb3();
    const rewardRefContract = getRewardRefContract('0xb5f081b09b4ff62de3e39f74d3a76ddfaa29dd8e', web3)
    const swapRefContract = getSwapRefContract('0x356209692c2430e5dfc78567a8b4ea2351063550', web3)
    const masterChefRefContract = getMasterChefRefContract('0x356209692c2430e5dfc78567a8b4ea2351063550', web3)
    return {rewardRefContract, swapRefContract, masterChefRefContract}
}
