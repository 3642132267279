import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'
import Web3 from 'web3'

export const fetchFarmUserAllowances = async (account: string, farmsToFetch: FarmConfig[], web3: Web3) => {
  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, farm?.masterChefAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls, web3)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string, farmsToFetch: FarmConfig[], web3: Web3) => {
  const liveFarms = farmsToFetch.filter(item => item.multiplier !=='0X')
  const calls = liveFarms.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls, web3)
  let parsedTokenBalances: any = {};

  rawTokenBalances.forEach((tokenBalance, idx) => {
    parsedTokenBalances[liveFarms[idx].id] = new BigNumber(tokenBalance).toJSON()
  })

  return farmsToFetch.map(item =>{
    return parsedTokenBalances[item.id] || new BigNumber(0).toJSON()
  })
}

export const fetchFarmUserStakedBalances = async (account: string, farmsToFetch: FarmConfig[], web3: Web3) => {
  const calls = farmsToFetch.map((farm) => {
    return {
      address: farm.masterChefAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(masterchefABI, calls, web3)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: FarmConfig[], web3: Web3) => {
  const liveFarms = farmsToFetch.filter(item => item.multiplier !== '0X')
  const calls = liveFarms.map((farm) => {
    return {
      address: farm.masterChefAddress,
      name: 'pendingReward',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls, web3)
  let parsedEarnings: any = {}
  rawEarnings.forEach((earnings, idx) =>{
    parsedEarnings[liveFarms[idx].id] = new BigNumber(earnings).toJSON()
  })

  return farmsToFetch.map(item =>{
    return parsedEarnings[item.id] || new BigNumber(0).toJSON()
  })
}

export const fetchUnfrozenDepositTime = async (account: string, farmsToFetch: FarmConfig[], web3: Web3) => {
  const liveFarms = farmsToFetch.filter(item => item.multiplier !== '0X')
  const calls = liveFarms.map((farm) => {
    return {
      address: farm.masterChefAddress,
      name: 'unfrozenDepositTime',
      params: [farm.pid, account],
    }
  })

  const rawTimes = await multicall(masterchefABI, calls, web3)
  let parsed: any = {}
  rawTimes.forEach((data, idx) =>{
    parsed[liveFarms[idx].id] = parseInt(data)
  })

  return farmsToFetch.map(item =>{
    return parsed[item.id] || 0
  })
}
