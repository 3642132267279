import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    id: 0, // masterchef 2.1
    sousId: 0,
    stakingToken: tokens.clx,
    earningToken: tokens.clx,
    contractAddress: {
      80001: '',
      137: '0xf61fdc0F479305a0E7566bBeAB46196bc0aFd997',
      42220: '0x806c374aC7a2A67e2B2EA31AF369b60a6A650fC2'
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '32',
    tokenPerSecond: 16.24,
    sortOrder: 0,
    isFinished: false,
    isLocked: false,
    isTaxed: false,
  }
]

export default pools
