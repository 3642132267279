import React, { lazy, useEffect } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS } from '@polydex/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useFetchPriceList, useFetchProfile, useFetchPublicData } from 'state/hooks'
import bs58 from 'getbs58'
import axios from 'axios'
import { useWeb3React } from '@web3-react/core'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import EasterEgg from './components/EasterEgg'
import Pools from './views/Pools'
import Ref from './views/Ref'
import history from './routerHistory'
import 'antd/dist/antd.css'
import { NftConfig } from 'config/constants/NftPlatform'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Lottery = lazy(() => import('./views/Lottery'))
const Ifos = lazy(() => import('./views/Ifos'))
const NotFound = lazy(() => import('./views/NotFound'))
const Collectibles = lazy(() => import('./views/Collectibles'))
const Teams = lazy(() => import('./views/Teams'))
const Team = lazy(() => import('./views/Teams/Team'))
const Profile = lazy(() => import('./views/Profile'))
const TradingCompetition = lazy(() => import('./views/TradingCompetition'))
const Referral = lazy(() => import('./views/Referral'))
const Predictions = lazy(() => import('./views/Predictions'))
const VestingTokens = lazy(() => import('./views/VestingTokens'))
const IDO = lazy(() => import('./views/Ido'))
const Store = lazy(() => import('./views/Store/NFTComponent'))
const DetailNFT = lazy(() => import('./views/DetailsNFT'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  useEagerConnect()
  useFetchPublicData()
  useFetchProfile()
  useFetchPriceList()
  const { account } = useWeb3React()

  let inviteA = ''
  let paramsA = window.location.search?.split('?')
  if (paramsA[1]) {
    paramsA = paramsA?.[1]?.split('=')
    inviteA = paramsA[0] === 'referral' ? paramsA[1] : ''
  }
  if (inviteA) {
    try {
      const refStorage: string | null = localStorage.getItem('refAddressPolyDex') || ''
      if (!refStorage) {
        const refByAddress = `0x${bs58.decode(inviteA)}`
        localStorage.setItem('refAddressPolyDex', refByAddress)
      }
    } catch (e: any) {
      console.error(e)
    }
  }

  useEffect(() => {
    const linkGame: any = document.querySelector("[href='https://games.interdefi.org']")

    if (linkGame && !linkGame.target) {
      linkGame.target = '_blank'
    }
  }, [])

  useEffect(() => {
    if (!account) return

    const refStorage: string | null = localStorage.getItem('refAddressPolyDex') || ''
    if (refStorage) {
      const paramsAddress = { address: account.toLowerCase(), refByAddress: refStorage.toLowerCase(), signMessage: '' }
      axios.post('https://api.polydex.fi/api/public/referrals', paramsAddress).then((res) => console.log(res))
    }
  }, [account])

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/pools">
              <Pools />
            </Route>
            <Route exact path="/future-plx">
              <VestingTokens />
            </Route>
            <Route exact path="/poly-vesting">
              <VestingTokens />
            </Route>
            <Route exact path="/polystore/my-nft">
              <Store />
            </Route>
            <Route exact path="/polystore">
              <Store />
            </Route>

            {Object.values(NftConfig).map((platform) => {
              const { router, platformId } = platform

              return (
                <Route exact path={`/${router}/:id`}>
                  <DetailNFT url={router} platformId={platformId} />
                </Route>
              )
            })}

            <Route exact path="/polystore/create-nft">
              <Store />
            </Route>

            {/* <Route path="/lottery">
              <Lottery />
            </Route>
            <Route path="/ifo">
              <Ifos />
            </Route>
            <Route path="/collectibles">
              <Collectibles />
            </Route>
            <Route exact path="/teams">
              <Teams />
            </Route>

            <Route path="/teams/:id">
              <Team />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/competition">
              <TradingCompetition />
            </Route>
            <Route path="/prediction">
              <Predictions />
            // </Route> */}

            <Route path="/referral">
              <Ref />
            </Route>
            {/* Redirect */}
            <Route path="/staking">
              <Redirect to="/pools" />
            </Route>
            <Route path="/syrup">
              <Redirect to="/pools" />
            </Route>
            {/* <Route path="/nft">
              <Redirect to="/collectibles" />
            </Route> */}
            <Route path="/ido">
              <IDO />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)
