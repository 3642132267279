import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Input, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
interface Props {
  data: any[]
}
const columns = [
  {
    title: 'Wallet',
    dataIndex: 'address',
    key: 'wallet',
  },
  {
    title: 'SWAP share',
    dataIndex: 'swapReward',
    key: 'swapReward',
  },
  {
    title: 'CeloFARM share',
    dataIndex: 'farmReward',
    key: 'farmReward',
  },
  {
    title: 'Total share',
    dataIndex: 'totalShare',
    key: 'totalShare',
  },
]

const TableContainer = styled.div`
  width: 100%;
  min-width: 822px;
  border-radius: 16px;
  box-shadow: rgb(195 236 201) 1px 1px 20px 1px;
  margin-bottom: 80px;
  .ant-table-pagination-right {
    margin-right: 20px;
  }
  .table-header {
    background: rgb(226, 255, 236);
    width: 100%;
    height: 70px;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    min-width: 822px;
    .table-title {
      font-weight: 500;
    }
    .table-search-box {
      .ant-input-affix-wrapper {
        background: none;
        border: none;
        border-bottom: solid 1px #888;
        .ant-input-prefix {
          font-size: 18px;
        }
        input {
          background: none;
          &:focus {
            outline: none;
            border-right-width: 0 !important;
          }
        }
      }
    }
  }
  .table-body {
    padding: 0;
    th {
      background: none;
      border: none;
      color: #05d372;
    }
    tr:hover {
      /* background: #EAF7FF !important; */
    }
  }
`

export default function RefTable() {
  const [tableData, setTableData] = useState([])
  const { account } = useWeb3React()

  const fetchData = async () => {
    let response = await axios.get(`https://api.polydex.fi/api/public/referrals?address=${account}`)
    const data = response?.data?.data.map((item) => {
      return {
        ...item,
        totalShare: item.swapReward + item.farmReward,
      }
    })
    setTableData(data)
  }
  useEffect(() => {
    if (account) {
      fetchData()
    }
  }, [account])
  return (
    <TableContainer>
      <div className="table-header">
        <div className="table-title">
          <span style={{ color: '#2b3033', fontSize: '20px' }}>Referrals list </span>
          <span style={{ color: '#05d372' }}>&nbsp;({tableData.length} active referrals)</span>
          <span></span>
        </div>
        <div className="table-search-box">
          <Input placeholder="search referrals" prefix={<SearchOutlined />} />
        </div>
      </div>
      <div className="table-body">
        <Table columns={columns} dataSource={tableData} />
      </div>
    </TableContainer>
  )
}
