import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'PolyDex',
  description:
    'The most popular AMM on Polygon (MATIC) by user count! Earn CLX through yield farming or win it in the Lottery, then stake it in PolyHERO to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PolyDex), NFTs, and more, on a platform you can trust.',
  image: '',
}

export const customMeta: { [key: string]: PageMeta } = {
  '/': {
    title: 'Home | CeloDex',
  },
  '/competition': {
    title: 'Trading Battle | CeloDex',
  },
  '/prediction': {
    title: 'Prediction | CeloDex',
  },
  '/farms': {
    title: 'Farms | CeloDex',
  },
  '/pools': {
    title: 'Pools | CeloDex',
  },
  '/lottery': {
    title: 'Lottery | CeloDex',
  },
  '/collectibles': {
    title: 'Collectibles | CeloDex',
  },
  '/ifo': {
    title: 'Initial Farm Offering | CeloDex',
  },
  '/teams': {
    title: 'Leaderboard | CeloDex',
  },
  '/profile/tasks': {
    title: 'Task Center | CeloDex',
  },
  '/profile': {
    title: 'Your Profile | CeloDex',
  },
}
